import React from "react";
import Wrapper from "../wrapper";
import * as styles from "./styles.module.scss";

const GetInTouch = () => {
  return (
    <>
      <div id="get-in-touch" className={styles.section}>
        <Wrapper>
          <div className={styles.mask}>
            <h1>Get in touch</h1>
            <p>Feel free to get in touch so we can book in a free telephone consultation where I can introduce myself and how I work.</p>
            <div className={styles.list}>
              <p>Telephone: <a href="tel:07359363131">
                  <span>07359363131</span>
                </a>
              </p>
              <p>
                Email: <a href="mailto:therapy@hannahbyrne.org">
                  <span>therapy@hannahbyrne.org</span>
                </a>
              </p>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default GetInTouch;
