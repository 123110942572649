import React from 'react';
import * as wrapperStyles from './wapper.module.scss';

interface Props {
    children: React.ReactNode;
}

const Wrapper = ({ children }: Props) => 
    <div className={wrapperStyles.wrapper}>{children}</div>


export default Wrapper;