import React from "react";
import Wrapper from "../wrapper";
import * as footerStyles from "./footer.module.scss";

const Footer = () => (
  <div className={footerStyles.footer}>
    <Wrapper>
      <p>&copy; Copyright 2023</p>
    </Wrapper>
  </div>
);

export default Footer;
