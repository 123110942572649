import * as React from "react";
import type { HeadFC } from "gatsby";
import '../styles/global.scss';
import MainHeader from "../components/main-header";
import Hero from "../components/hero";
import About from "../components/about";
import Welcome from "../components/welcome";
import WhatToExpect from "../components/what-to-expect";
import Footer from "../components/footer";
import Qualifications from "../components/qualifications";
import GetInTouch from "../components/get-in-touch";

const seo = {
  title: `Hannah Byrne Counselling & Psychotherapy`,
  description: `Hannah Byrne deliverying Counselling & Psychotherapy in the Trafford and Manchester areas`,
}

const IndexPage = () => {
  return (
    <>
      <MainHeader />
      <Hero />
      <Welcome />
      <About />
      <WhatToExpect />
      <Qualifications />
      <GetInTouch />
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  return <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
  </>
};
