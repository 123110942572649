import React from "react";
import Wrapper from "../wrapper";
import * as styles from "./qualifications.module.scss";
import bcapImage from '../../images/bcap.png';

const Qualifications = () => {
  return <>
    <div id="qualifications" className={styles.section}>
      <Wrapper>
      <div className={styles.mask}>
        <h1>Qualifications</h1>
        <div className={styles.list}>
          <ul>
            <li>
            <span>Certificate in counselling - Level 3</span>
            </li>
            <li>
            <span>Post graduate diploma in counselling and psychotherapy - Level 7 </span>
            </li>
            <li>
              <span>Mental health Nursing - Degree</span>
            </li>
            <li>
            <span>Arts for the blues training</span>
            </li>
            <li>
              <span>CPD - Grief & Loss through Trafford Wellbeing service</span>
            </li>
            <li>
              <span>More than Words - Trauma Therapy Manchester</span>
            </li>
          </ul>
          <p><img src={bcapImage} /></p>
        </div>
        </div>
      </Wrapper>
    </div>
  </>
};

export default Qualifications;
