import React from "react";
import Wrapper from "../wrapper";
import * as sectionStyles from "./section.module.scss";

interface SectionProps {
  id: string;
  title: string;
  body: any;
  img: string;
  contentOnLeft: boolean;
}

const Section = ({ id, title, body, img, contentOnLeft}: SectionProps) => (
  <div id={id} className={sectionStyles.section}>
    <Wrapper>
      <div className={sectionStyles.mask}>
        <div className={contentOnLeft ? `${sectionStyles.content} ${sectionStyles.contentLeft}` : sectionStyles.content}>
          <div className={sectionStyles.copy}>
            <h1 className={sectionStyles.title}>{title}</h1>
            <div className={contentOnLeft ? `${sectionStyles.img} ${sectionStyles.imgLeft}` : sectionStyles.img}>
              <img src={img} />
            </div>
            {body}
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
);

export default Section;
