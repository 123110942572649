import React, { useState } from "react";
import Wrapper from "../wrapper";
import Hamburger from "../hamburger";
import * as headerStyles from "./main-header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const MainHeader = () => {
  const [showNav, setShowNav] = useState(false);

  const hamburgerPress = () => {
    setShowNav(!showNav);
  }

  const closeNav = () => {
    setShowNav(false);
  }

  return <>
  <header className={headerStyles.header}>
    <div className={headerStyles.wrapper}>
      <div className={headerStyles.mask}>
        <h1 className={headerStyles.logo}>
          Hannah Byrne <span>Counselling & Psychotherapy</span>
        </h1>
        <nav className={showNav ? `${headerStyles.nav} ${headerStyles.navShow}` : `${headerStyles.nav}`}>
          <button type="button" className={headerStyles.close} onClick={closeNav}>Close</button>
          <ul>
          <li>
              <a href="#welcome" onClick={closeNav}>Welcome</a>
            </li>
            <li>
              <a href="#about-me" onClick={closeNav}>About me</a>
            </li>
            <li>
              <a href="#what-to-expect" onClick={closeNav}>What to expect</a>
            </li>
            <li>
              <a href="#qualifications" onClick={closeNav}>Qualifications</a>
            </li>
            <li>
              <a href="#get-in-touch" onClick={closeNav}>Get in touch</a>
            </li>
          </ul>
        </nav>
        <div className={headerStyles.top}>
          <div>
            <a href="tel:07359363131">
              <FontAwesomeIcon icon={faPhone} /> <span>07359363131</span>
            </a>
            <a href="mailto:therapy@hannahbyrne.org">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <span>therapy@hannahbyrne.org</span>
            </a>
          </div>
        </div>
        <Hamburger press={hamburgerPress} />
      </div>
    </div>
  </header>
  </>
};

export default MainHeader;
