import React from 'react';
import * as hamburgerStyles from './hamburger.module.scss';

type HamburgerProps = {
  press: () => void;
}

const Hamburger = ({press}: HamburgerProps) => 
<button type='button' className={hamburgerStyles.hamburger} onClick={press}>
  <em></em>
  <em></em>
  <em></em>
  <span>Menu</span>
</button>

export default Hamburger;