import React from "react";
import AboutCopy from "./about-copy";
import Section from "../section";
import image from '../../images/me.jpg';

const About = () => {
  const title = 'About me';

  return <Section id="about-me" title={title} body={<AboutCopy />} img={image} contentOnLeft={false} />
};

export default About;
