import React from "react";

const WelcomeCopy = () => (
  <>
    <p>
      We all experience times in our lives when things feel too much for us, our
      emotions can be frightening and scary. We can feel so very overwhelmed,
      stuck or simply lost. We may have experienced a major change in our lives,
      such as loss which comes in so many different forms, such as
      relationships, work, experiencing a long term illness, the death of a
      loved one or even losing our sense of who we are.
    </p>
    <p>
      Counselling is a talking therapy that helps unravel and unpick those very
      difficult feelings we are having. It is a unique space and relationship
      allowing us to really be heard and really hear our own voices above all
      others, without judgement. It allows us an opportunity to make sense out
      of what we have or are experiencing and explore a new way of doing or
      being. It is important to find someone whom you feel comfortable with when
      entering into therapy, someone who you can be with and sit with into the
      most hard to reach places of yourself. If you're considering therapy I
      offer a free telephone consultation to allow you time and space to see how
      you feel with me and to ensure we are the right fit for you.
    </p>
    <p>
      I Have two bases where I can see people face to face, one in Carrington at
      the Nebula centre and another in the centre of Urmston.
    </p>
  </>
);

export default WelcomeCopy;
