import React from "react";

const AboutCopy = () => (
  <>
    <p>
      Hello my name is Hannah, I've always held a passion for working with
      wellbeing working in the field of mental health for many years. Over time
      I transitioned and retrained as a person centred counsellor. Whilst I work
      in many different areas within counselling I hold a passion for working
      within the area of loss. It truly is a privilege to work with people
      experiencing the darkest and most painful times in their lives and being
      able to support them in their grief and loss, and is something I am very
      passionate about.
    </p>
    <p>
      Through my experience as a volunteer counsellor for the Macmillan
      Wellbeing centre based in Trafford I have developed a specialism in
      supporting people experiencing loss and bereavement, as well as those who
      have received a cancer diagnosis and those caring for a loved one with a
      cancer diagnosis or long term illness. When we lose a loved one we are
      affected in so many different ways and all parts of us are deeply
      affected, there is not one part of our lives the pain of grief does not
      touch. I can be with you and support you in those moments and we will work
      through your loss together.
    </p>
  </>
);

export default AboutCopy;
