import React from "react";

const WhatToExpectCopy = () => (
  <>
    <p>
      I understand how hard taking the first step to seeking support is, and
      finding the right person to work with you is so very important. With this
      in mind I offer a free telephone consultation where I can introduce myself
      and how I work. We can think about what has brought you to this point and
      what you would like to gain from counselling. Together we can explore how
      we would work together as we are all unique.
    </p>
    <p>
      Following if you feel ready we can book an appointment in a mode that
      feels right for you. Some people prefer to work face to face, others over
      the telephone, some people online. I have two current bases where I can
      see people face to face, The Nebula Centre in Carrington, which is a
      beautiful haven of peace and calm, and a room in Centre of Urmston.
    </p>
    <h3>What next</h3>
    <p>
      Sessions are 50 minutes with a designated hour for our time together
      giving us time as each session closes to make further arrangements or
      reflect on where you feel in your therapy journey.
    </p>
    <h3>Fees</h3>
    <p>£50 a session</p>
  </>
);

export default WhatToExpectCopy;
