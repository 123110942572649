import React from "react";
import Section from "../section";
import WhatToExpectCopy from "./what-to-expect-copy";
import image from '../../images/img-0045-min.jpg';

const WhatToExpect = () => {
  const title = 'What to expect';

  return <Section id="what-to-expect" title={title} body={<WhatToExpectCopy />} img={image} contentOnLeft={true} />
};

export default WhatToExpect;
