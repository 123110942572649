import React from "react";
import Section from "../section";
import WelcomeCopy from './welcome-copy';
import image from '../../images/img-0042-min.jpg';

const Welcome = () => {
  const title = 'Welcome';

  return <Section id="welcome" title={title} body={<WelcomeCopy />} img={image} contentOnLeft={true} />
};

export default Welcome;
